.coins-history-table {
  @apply border-collapse table-auto text-left;
}

.coins-history-table > thead {
  @apply bg-secondary font-bold text-white;
}

.coins-history-table > thead > tr {
  /* @apply */
}

.coins-history-table > thead > tr > th {
  @apply px-2 py-1;
}

.coins-history-table > tbody {
  @apply text-sm;
}

.coins-history-table > tbody > tr {
  @apply border hover:bg-blue-100 hover:cursor-pointer;
}

.coins-history-table > tbody > tr > td {
  @apply px-2 py-1;
}
