@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sansita:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@layer base {
  html,
  body {
    margin: 0;
  }

  :root {
    --toastify-color-info: theme('colors.info') !important;
    --toastify-color-success: theme('colors.success') !important;
    --toastify-color-warning: theme('colors.warning') !important;
    --toastify-color-error: theme('colors.danger') !important;
  }

  body {
    color: theme('colors.secondary');
    font-family: 'Sansita', sans-serif;
  }
}

@layer utilities {
  .h-stack {
    @apply flex flex-row;
  }

  .h-stack-wrap {
    @apply flex flex-row flex-wrap;
  }

  .v-stack {
    @apply flex flex-col;
  }

  .v-stack-wrap {
    @apply flex flex-col flex-wrap;
  }
}

@layer components {
  .btn {
    @apply px-2 pb-1 border-2 border-transparent hover:border-secondary leading-none rounded-md bg-ternary outline-none transition ease-in-out duration-100 cursor-pointer disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-auto;
  }

  .btn-secondary {
    @apply px-2 pb-1 border-2 border-transparent leading-none rounded-md bg-secondary hover:border-ternary text-ternary outline-none transition ease-in-out duration-100 cursor-pointer;
  }

  .btn-danger {
    @apply px-2 pb-1 border-2 border-transparent leading-none rounded-md bg-danger hover:border-secondary text-white outline-none transition ease-in-out duration-100 cursor-pointer;
  }

  .input {
    @apply border-2 border-secondary rounded-md px-2 py-1 leading-none focus:border-ternary outline-none;
  }

  .btn-action {
    @apply w-14 h-14 h-stack justify-center items-center;
    @apply rounded-full text-2xl p-1;
    @apply bg-ternary text-secondary;
    @apply border-2 border-secondary;
    @apply z-10;
    @apply fixed bottom-2  right-2 sm:bottom-5 sm:right-5;
    @apply transition;
    @apply hover:scale-110 hover:bg-secondary hover:text-ternary;
    @apply disabled:bg-gray-300 disabled:text-secondary disabled:scale-100;
  }
}
