.members-table {
  @apply border-collapse table-auto w-full text-left;
}

.members-table > thead {
  @apply uppercase bg-secondary text-white;
}

.members-table > thead > tr {
  /* @apply */
}

.members-table > thead > tr > th {
  @apply px-3 py-2;
}

.members-table > tbody {
  @apply text-sm;
}

.members-table > tbody > tr {
  @apply border hover:bg-blue-100 hover:cursor-pointer;
}

.members-table > tbody > tr > td {
  @apply px-3 py-2;
}
